<template>
  <div v-if="!isLoadingInterview">
    <ForYouCard
      v-if="interview.status === 'COMPLETED'"
      :title="$t(itwData.title)"
      :description-small="$t(itwData.subtitle)"
      :description="description"
      color="red"
      @click="
        $router.push({
          name: 'collection-detail',
          params: { id: 'interview|interview' },
        })
      "
    />
    <div
      v-else
      class="w-[216px] h-[216px] md:w-[248px] md:h-[248px] rounded-2xl overflow-hidden bg-red-500/[.08] flex-shrink-0
      transition ease-in-out duration-500
      hover:shadow-wide
      focus:outline-2
      focus:outline-red-5000"
      tabindex="0"
    >
      <div class="relative">
        <LogoInterviewRound />
      </div>
      <div class="flex flex-col h-full justify-between p-4">
        <div v-if="['PREPROCESSING', 'UNDERREVIEW', 'VERIFYING', 'VERIFIED', 'FAILED'].indexOf(interview.status) > -1">
          <div
            class="w-8 h-8 rounded-full flex justify-center items-center text-xl"
            :class="{
              'bg-blue-900 text-blue-100': ['PREPROCESSING', 'UNDERREVIEW', 'VERIFYING', 'VERIFIED'].indexOf(interview.status) > -1,
              'bg-orange-500 text-orange-50': interview.status === 'FAILED',
            }"
          >
            <i
              class="uil"
              :class="{
                'uil-hourglass': ['PREPROCESSING', 'UNDERREVIEW', 'VERIFYING', 'VERIFIED'].indexOf(interview.status) > -1,
                'uil-exclamation-octagon': interview.status === 'FAILED',
              }"
            />
          </div>
        </div>
        <div>
          <div class="text-base sm:text-lg font-bold text-blue-900">
            {{ $t(itwData.title) }}
          </div>
          <div
            v-if="itwData.subtitle"
            class="text-blue-900 mt-5 md:mt-14"
          >
            {{ $t(itwData.subtitle) }}
          </div>
        </div>
        <Button
          v-if="itwData.has_cta"
          data-test="banner-cta"
          severity="secondary"
          icon="uil-arrow-right"
          icon-pos="right"
          class="p-0 sm:p-3 underline w-fit"
          :label="$t(itwData.has_cta)"
          @click="itwData.cta_action()"
        />
      </div>
    </div>
    <InterviewModal
      v-model="showDialog"
      :mode="mode"
    />
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import InterviewModal from "@/views/InterviewModal.vue";
import LogoInterviewRound from "@/components/cards/LogoInterviewRound.vue";
import Button from "primevue/button";
import ForYouCard from "@/components/cards/ForYouCard.vue";
import {useInterview} from "@/composables/useInterview";


defineProps({
  description: { type: String, default: '' }
})
const { isLoadingInterview, interview } = useInterview()

const mode = ref('initialize')
const showDialog = ref(false)
const itwData = computed(() => {
    return {
      'title': !interview.value.status ? 'interview.none_title' : (
        ['CREATED', 'INCREATION'].indexOf(interview.value.status) > -1 ? 'interview.created_title' : (
        ['PREPROCESSING', 'UNDERREVIEW', 'VERIFYING', 'VERIFIED'].indexOf(interview.value.status) > -1 ? 'interview.in_progress_title' : (
        interview.value.status === 'FAILED'? 'interview.banner_failed' : (
        interview.value.status === 'COMPLETED'? 'interview.done_title' : ''
      )))),
      'subtitle': interview.value.status === 'COMPLETED' ? 'interview.done_subtitle' :
        (['PREPROCESSING', 'UNDERREVIEW', 'VERIFYING', 'VERIFIED'].indexOf(interview.value.status) > -1 ? 'interview.in_progress_subtitle' : ''),
      'has_cta': !interview.value.status ? 'interview.none_cta' : (
        ['CREATED', 'INCREATION'].indexOf(interview.value.status) > -1  ? 'interview.created_button' : (
        interview.value.status === 'FAILED' ? 'interview.failed_cta' : ''
      )),
      'cta_action': [null, '', 'CREATED', 'INCREATION', 'FAILED' ].indexOf(interview.value.status) > -1 ? showInterviewModal : doNothing,
    };
  }
)
const doNothing = () => {}
const showInterviewModal = () => {
    mode.value = ['CREATED', 'INCREATION'].indexOf(interview.value.status) > -1 ? 'continue' : 'initialize';
    showDialog.value = true;
}
</script>
