import TrackedModel from "@/resources/TrackedModel";
import Subscription from "@/resources/Subscription";
import {useUserRoles} from "@/composables/userData";

export default class Plan extends TrackedModel {

  constructor(data) {
    super(data);

    const { userIsAdmin, userIsSales, userIsSalesManager, userIsOnlySales, userId, userIsOrganizationAdmin, userOrganizationId } = useUserRoles();
    this.organization = data.organization || "";
    this.subscription = data.subscription || "";
    this.status = data.status || "";
    this.subscription_display = data.subscription_display || "";
    this.organization_name = data.organization_name || "";
    this.organization_info = data.organization_info || {};
    this.total_license = data.total_license || 0;
    this.used_license = data.used_license || 0;
    this.used_on_total_license = this.used_license.toString() + ' / ' + this.total_license.toString();
    this.start_date = data.start_date ? TrackedModel.asDate(data.start_date) : null;
    this.current_start_date = data.current_start_date ? TrackedModel.asDate(data.current_start_date) : this.start_date;
    this.expiration_date = data.expiration_date ? TrackedModel.asDate(data.expiration_date) : null;
    this.renewal_date = data.renewal_date ? TrackedModel.asDate(data.renewal_date) : null; // (this.expiration_date ? this.expiration_date.setDate(this.expiration_date.getDate() +1): null);
    this.renewalDateStr = data.renewal_date ? TrackedModel.asOutputDate(data.renewal_date, 'it') : '';
    this.stripe_subscription_id = data.stripe_subscription_id;
    this.stripe_price_id = data.stripe_price_id;
    this.billing_frequency = data.billing_frequency;
    this.price = data.price || 0;
    this.trial_end_date = data.trial_end_date ? TrackedModel.asDate(data.trial_end_date) : null;
    this.payment_date = data.payment_date ? TrackedModel.asDate(data.payment_date) : null;
    this.payed = !!data.payment_date;
    this.users_subscriptions = data.users_subscriptions || [];

    // const today = TrackedModel.asInputDate(new Date());
    this.is_expired = data.status === 'expired'; // data.expiration_date < today;
    this.isCurrentUserSales = (userIsOnlySales() && data.organization_info?.sales === userId()) || userIsSalesManager()

    this.permissions.edit = data.status !== 'expired' && (
      (userIsOrganizationAdmin() && userOrganizationId() === data.organization && !!data.stripe_subscription_id)
      || ((userIsAdmin() || this.isCurrentUserSales) && !data.stripe_subscription_id)
      )
    ;
    this.permissions.delete = data.status === 'not_started' && (userIsAdmin() || userIsSales());
    // this.permissions.setpayed = !this.payed && userIsAdmin(); // TODO check

    this.permissions.select = (this.total_license > this.used_license) && (!this.is_expired);
    this.permissions.manage = ((userIsOrganizationAdmin() || userIsSales()) && userOrganizationId() === data.organization)
                            || ((userIsAdmin() || this.isCurrentUserSales) && !this.stripe_subscription_id);
    this.permissions.renewal = (userIsAdmin() || this.isCurrentUserSales) && !this.stripe_subscription_id && ['not_started', 'active', 'expiring'].indexOf(this.status) > -1;
    this.disabled = this.is_expired;

    if (data.users_subscriptions) {
      this.users_subscriptions = data.users_subscriptions.map(sub =>
        Subscription.fromApiResult(sub));
    }

    this.expirationDate = null;
    this.realExpirationDate = null;
    // 2gg (today + 2days)
    if (data.expiration_date) {
      this.expirationDate = TrackedModel.asDate(data.expiration_date)
      this.realExpirationDate = this.expirationDate.setDate(this.expirationDate.getDate() + 2)
    }

    return this;
  }

  static getValidListOptions(records, fieldName, defaultEmptyLabel, addEmptyItem=true){
    let forListBoxOption = [];
    if (addEmptyItem) {
      forListBoxOption.push({key: 0, label: defaultEmptyLabel, start_date: '', expiration_date: '', subscription: 0});
    }
    forListBoxOption = forListBoxOption.concat(records.map(record => {
      return record.convertForListBoxOption();
    }));

    return forListBoxOption;
  }



  convertForListBoxOption() {
    return {
      key: this.id,
      label: (this.organization_info?.personal ? 'Personal' : 'Business') + ' (' + this.used_license + '/' + this.total_license + ')', // TODO translation
      start_date: TrackedModel.asOutputDate(this.start_date, 'it'),
      expiration_date: TrackedModel.asOutputDate(this.expiration_date, 'it'),
      expiration_date_date: this.expiration_date,
      subscription: this.subscription,
      used_license: this.used_license,
      total_license: this.total_license,
      disabled: this.is_expired || this.total_license <= this.used_license
    };
  }

  static fromApiResult(planData) {
    const planObj = new Plan(planData);
    return planObj;
  }

  static forApi(recordObj) {
    let recordData = {};
    const dateFields = ['start_date', 'expiration_date', 'payment_date', 'trial_end_date', 'current_start_date', 'renewal_date'];
    Object.getOwnPropertyNames(recordObj).forEach(key => {
      if (dateFields.indexOf(key) > -1 && recordObj[key]) {
        recordData[key] = TrackedModel.asInputDate(recordObj[key]);
      } else {
        recordData[key] = recordObj[key];
      }
    });
    return recordData;
  }

  thisForApi() {
    return Plan.forApi(this);
  }

  static parseError(errorObject) {
    let errors = [];
    const keys = Object.keys(errorObject);
    if (Array.isArray(keys) && keys.length > 0) {
      errorObject.map((fieldError) => {
        let msg = Array.isArray(fieldError) ? Object.values(fieldError) : fieldError;
        errors.push(msg);
      });
    } else {
      errors.push("Operazione fallita");
    }
    return '<li>'+errors.join('</li><li>')+'</li>';
  }

  static mapPlans = {
    personal: 9,
    business: 12,
  }

}
