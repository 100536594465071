import { createRouter, createWebHistory } from 'vue-router';
// layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// views
import Register from '@/views/Register.vue';
import LoginRedirect from '@/views/LoginRedirect.vue';
// import ForgotPass from '@/views/ForgotPass.vue'
// import ResetPass from '@/views/ResetPass.vue'
import NewCourseSearch from '@/views/Search/NewCourseSearch.vue';
import SearchResults from '@/views/Search/SearchResults.vue';
import StartInterview from '@/views/StartInterview.vue';
import FinishedLanding from '@/views/FinishedLanding.vue';
import { ROLES } from '@/api/user.js';
import { useUserData, useUserRoles, getCurrentSubscription } from '@/composables/userData.js';
import { useCourseResults } from '@/composables/useElasticCourseResults.js';
// import { getKeyCloak } from '@/composables/useSSO.js';
import { useAuthStore } from '../stores';
import config from '../config';
import NewForYou from '../views/NewForYou.vue';


const beforeEnterManage = (to, from, next) => {
  const { userIsAdmin, userIsSales, userIsOwnerOfOrg, userIsOrganizationAdmin, userIsManager, userOrganizationId } = useUserRoles();
  if (userIsAdmin()) {
    next();
    return;
  }
  console.log('beforeEnterManage', userIsSales(), to.params, userIsOwnerOfOrg(to.params.id))
  if (userIsSales() && userIsOwnerOfOrg(to.params.id)) {
    next();
  } else if ((userIsOrganizationAdmin() || userIsManager()) && userOrganizationId() === to.params.id) {
    next();
  } else {
    // TODO toast
    next('/');
  }
};

// const beforeEnterManageOnlyAdmin = (to, from, next) => {
//   const { userIsAdmin } = useUserRoles();
//   if (userIsAdmin()) {
//     next();
//     return;
//   }
//   next('/');
// };

const routes = [
  {
    path: '/',
    name: 'ForYou',
    component: NewForYou,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  // {
  //   path: '/api/v2/accounts/openid_connect/login/callback',
  //   name: 'HomeLogin',
  //   component: NewForYou,
  //   meta: {
  //     requiresAuth: true,
  //     layout: DefaultLayout,
  //   },
  // },
  {
    path: '/register/:contract/:language',
    name: 'Register',
    component: Register,
    meta: {
      layout: DefaultLayout,
      requiresAuth:false,
      requiresNoAuth: true,
      menu: false,
      navbar: false,
      registration: true
    },
    props: (route) => {
        return { contract: route.params.contract, language: route.params.language }
    }

  },
  {
    path: '/login/:language',
    name: 'login-redirect',
    component: LoginRedirect,
    meta: {
      layout: DefaultLayout,
      requiresAuth:false,
      requiresNoAuth: true,
      menu: false,
    },
    props: (route) => {
        return { language: route.params.language }
    }

  },
  {
    path: '/interview',
    name: 'InterviewStart',
    component: StartInterview,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      menu: true,
    },
  },
  {
    path: '/interview_landing',
    name: 'interview-end',
    component: FinishedLanding,
    props: {
      landingTarget: 'interview'
    },
    meta: {
      requiresAuth: false,
      layout: DefaultLayout,
    },
  },
  {
    path: '/questionnaire_landing',
    name: 'questionnaire-end',
    component: FinishedLanding,
    props:  {
      landingTarget: 'egg_up'
    },
    meta: {
      requiresAuth: false,
      layout: DefaultLayout,
    },
  },
  {
    path: '/search',
    name: 'search',
    component: NewCourseSearch,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  {
    path: '/search/results',
    name: 'search-results',
    component: SearchResults,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
    },
  },
  {
    path: '/detail/:id',
    name: 'detail',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/NewDetail.vue'),
    props: (route) => {
      return { id: route.params.id , clickSource: history.state.click_source }
    },
  },
  {
    path: '/sso/:itemId',
    name: 'sso-post',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
      menu: false,
      navbar: false,
    },
    component: () =>
      import(/* webpackChunkName: "sso-post" */ '../views/SSOPost.vue'),
    props: (route) => {
      return {
        itemId: route.params.itemId,
      }
    },
  },
  {
    path: '/career-coach',
    name: 'career-coach',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/CareerCoach.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
      modalAllowed: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    props: () => {
      return {
        selectedUserId: history.state.selectedUserId,
        selectedOrgId: history.state.selectedOrgId,
        selectedOrgName: history.state.selectedOrgName,
        activeTab: history.state.activeTab };
    },
  },
  {
    path: '/collection/:id',
    name: 'collection-detail',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "collection-detail" */ '../views/NewCollectionDetail.vue'),
    props: (route) => {
      return { id: route.params.id, fromSingleShare: history.state.fromSingleShare }
    },
  },
  {
    path: '/collections/:collectionType',
    name: 'collections-list',
    meta: {
      layout: DefaultLayout,
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "collections" */ '../views/CollectionList.vue'),
    props: (route) => {
      return { collectionType: route.params.collectionType }
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/privacy-policy',
  //   name: 'Privacy',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tos" */ '../views/Privacy.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/terms-of-service',
  //   name: 'TOS',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tos" */ '../views/TOS.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  //
  // {
  //   path: '/progress',
  //   name: 'Progress',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "user-progress" */ '../views/UserProgress.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     layout: DefaultLayout,
  //   },
  // },
  // {
  //   path: '/:pathMatch(.*)',
  //   name: 'Error',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "404" */ '../views/Error.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //   },
  // },
  {
    path: '/welcome',
    name: 'welcome',
    component: () =>
      import(
        '../views/Welcome.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      menu: false,
      navbar: true,
      onboarding: true,
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () =>
      import(
        '../views/Subscription.vue'
      ),
    meta: {
      layout: DefaultLayout,
      menu: false,
      navbar: true,
      subject: 'expired'
    },
  },

  //// ADMIN
  {
    path: '/admin/organization/groups/:id',
    name: 'organization-groups',
    // props: true,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "GroupList" */
        '../components/management/GroupList.vue'
      ),
    beforeEnter: beforeEnterManage,
    meta: {
      layout: DefaultLayout
    },
  },
  {
    path: '/admin/organization/groups/:id/:groupId/detail/',
    name: 'group-detail',
    props: true,
    beforeEnter: beforeEnterManage,
    meta: {
      layout: DefaultLayout
    },
    component: () =>
      import(
        /* webpackChunkName: "GroupUserList" */
        '../components/management/GroupUserList.vue'
      ),
  },
  {
    path: '/admin/organization/users/:id',
    name: 'organization-users',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UserList" */
        '../components/management/UserList.vue'
      ),
    beforeEnter: beforeEnterManage,
    meta: {
      requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER, ROLES.SUPERVISOR],
      layout: DefaultLayout
    },
  },
  {
    path: '/admin/organization/users/:id/import',
    name: 'organization-import-users',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UserImport" */
        '../components/management/UserImport.vue'
      ),
    beforeEnter: beforeEnterManage,
    meta: {
      requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER, ROLES.SUPERVISOR],
      layout: DefaultLayout
    },
  },
  {
    path: '/admin/organization/plans/:id',
    name: 'organization-plans',
    props: (route) => {
      return { id: route.params.id, fromOrganizationList: history.state.fromOrganizationList }
    },
    component: () =>
      import(
        /* webpackChunkName: "PlanTab" */
        '../components/management/plans/PlanTab.vue'
      ),
    beforeEnter: beforeEnterManage,
    meta: {
      requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER, ROLES.SUPERVISOR],
      layout: DefaultLayout
    },
  },
  {
    path: '/admin/organization/plans/:id/:planId/detail/',
    name: 'plan-detail',
    props: true,
    beforeEnter: beforeEnterManage,
    meta: {
      requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER, ROLES.SUPERVISOR],
      layout: DefaultLayout
    },
    component: () =>
      import(
        /* webpackChunkName: "PlanUserList" */
        '../components/management/plans/PlanUserList.vue'
      ),
  },
  {
    path: '/admin/organization/plans/:id/:addonId/addon/',
    name: 'addon-detail',
    props: true,
    beforeEnter: beforeEnterManage,
    meta: {
      requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER, ROLES.SUPERVISOR],
      layout: DefaultLayout
    },
    component: () =>
      import(
        /* webpackChunkName: "AddonUserList" */
        '../components/management/plans/AddonUserList.vue'
      ),
  },
  {
    path: '/admin/organization/:id',
    name: 'organization-edit',
    // name: 'organization',
    props: (route) => {
      return { organizationId: route.params.id }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OrganizationForm" */
        '../components/management/OrganizationForm.vue'
      ),
    beforeEnter: beforeEnterManage,
    meta: {
      layout: DefaultLayout,
      requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER, ROLES.SUPERVISOR, ROLES.MANAGER],
      modalAllowed: true,
    }
  },
  // {
  //   path: '/organization/:id/user/signup',
  //   name: 'organization-users-signup',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "profile" */
  //       '../views/OrganizationUsersSignup.vue'
  //     ),
  //   props: (route) => {
  //     return { id: route.params.id }
  //   },
  //   meta: {
  //     layout: AdminDefaultLayout
  //   },
  // },
  {
    path: '/admin/organizations',
    name: 'organizations',
    component: () =>
      import(
        /* webpackChunkName: "organizations-page" */
        '../components/management/OrganizationList.vue'
      ),
    meta: {
      layout: DefaultLayout,
      requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER],
      modalAllowed: true,
    },
    children: [
      {
        path: '/create',
        name: 'create-organization',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "organization-form" */
            '../components/management/OrganizationForm.vue'
          ),
        meta: {
          layout: DefaultLayout,
          requiresAuth: [ROLES.ADMIN, ROLES.SALES, ROLES.SALESMANAGER],
        },
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page404',
    component: () =>
      import(
        /* webpackChunkName: "PageNotFound" */
        '../views/PageNotFound.vue'
      ),
    meta: {
      layout: DefaultLayout,
      menu: false,
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(/*to, from, savedPosition*/) {
    // always scroll to top
    return { top: 0 }
  },
  // scrollBehavior: function (to) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash
  //     }
  //   }
  //   return { top: 0 }
  // },
  routes,
})

const isAuthorized = async (requiresAuth, isAuthenticated, user) => {
  if (!requiresAuth) {
    return true
  }

  if (requiresAuth && !isAuthenticated) {
    return false
  }

  if (requiresAuth === true && isAuthenticated) {
    return true
  }

  if (!user || !user.role) {
    return false
  }

  if (Array.isArray(requiresAuth) && requiresAuth.indexOf(user.role) === -1) {
    return false
  }
  return true
}

const allowedRouteForExpiredToo = ['Register', 'organization-plans', 'plan-detail', 'login-redirect'];


router.beforeEach(async (to, from) => {
  //const { initiateLogin } = useUserData()
  const { isAuthenticated, user } = await useUserData()

  const { searchTerm } = useCourseResults()
  // console.log('router waiting for KC')
  //await getKeyCloak()
  // check from authentication store if token was loaded
  const authStore = await useAuthStore();
  if(authStore.getKCToken() == null){
    await authStore.checkAuthentication()
  }

  if (!authStore.authenticated && to.meta.requiresAuth) {
    authStore.keycloak.login({redirectUri: config.FRONTEND_URL+to.path})
  }



  //AWAIT USER LOADED DATA
  while(!user.is_loaded){

    await new Promise(r => setTimeout(r, 100));
    // console.log(user.is_loaded)
  }
  /*
  if (Object.hasOwn(window, 'Matomo')){
  const matomo = window.Matomo.getTracker()
  console.log(user.email)
  if(matomo.getUserId() == ''){
  matomo.setUserId(user.email)
  matomo.trackPageView()
  matomo.enableLinkTracking()
  }
  }*/
  // console.log('router got KC')
  const subscription = getCurrentSubscription();
  const expired = !subscription || ['active', 'expiring', 'pending'].indexOf(subscription.plan_status) === -1

  const { userIsAnyAdmin } = useUserRoles();
  const { getUser } = useUserData();
  const currentUser = getUser()
  /*if (to.path == '/subscription' && !isAuthenticated.value){
    return { path: '/'};
  }*/

  // If it is authenticated but
  // - it is expired
  // - and the route is not allowed
  // - and the route requires auth or it isn't subject == 'expired', route will be subscription.

  if (isAuthenticated.value
    && allowedRouteForExpiredToo.indexOf(to.name) === -1
    && (expired
      || (subscription.plan_status === 'pending' && userIsAnyAdmin() && from.path === '/' && typeof from.name === 'undefined') /* in pending only for the first time */
    )
    && (to.meta.requiresAuth || to.meta.subject !== 'expired')
  ) {
    if (userIsAnyAdmin() && currentUser.organization_info && currentUser.organization_info.pay_in_app) {
      // let path = `/admin/organization/${user.organization_id}/plans`;
      // return { path: path, params: {isExpired: true} }
      // Modal is controlled in organization-plans page
      // if (subscription.plan_status === 'expired') {
      //   ModalBus.emit(ModalCommands.OPEN, {
      //     component: ModalForExpired,
      //     props: {
      //       fit: true
      //     }
      //   })
      // }
      return {name: 'organization-plans', params: {id: user.organization_id}}
    } else {
      return {name: 'subscription'}
    }
  }

  // if (!to.query.q && ['search', 'detail', 'collection-detail'].indexOf(to.name) === -1) {
  if (!to.query.q && to.name !== 'detail') {
    searchTerm.value = ''
  }

  if (to.meta.requiresNoAuth && isAuthenticated.value) {
    return {
      path: '/',
    }
  }


  const isAuthorizedEval = await isAuthorized(
    to.meta.requiresAuth,
    isAuthenticated.value,
    user
  )

  // console.log(to.meta.requiresAuth)
  // console.log(isAuthenticated.value)
  // console.log(isAuthenticated)

  // console.log('isAuthorizedEval', isAuthorizedEval)
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if(!isAuthorizedEval ){
    //await initiateLogin()
    authStore.keycloak.login({redirectUri: config.FRONTEND_URL+to.path})
  }



  // if (to.meta.requiresAuth === true && !isAuthenticated.value) {
  //   if (!isAuthorizedEval) {
  //     // this route requires auth, check if logged in
  //     // if not, redirect to login page.
  //     if (!isAuthenticated.value) {
  //       initiateLogin()
  //       // return {
  //       //   path: '/login',
  //       //   // save the location we were at to come back later
  //       //   query: { redirect: to.fullPath }
  //       // }
  //     } else {
  //       return {
  //         path: '/',
  //       }
  //     }
  //   }
  // }
})

// router.beforeEach(async (to, from, next) => {
//   document.title = `Viblio - ${to.meta.title || 'Il mentor digitale'}`
//   next()
// })

export default router
