<template>
  <div v-if="!isLoadingQuestionnaire">
    <ForYouCard
      v-if="questionnaire.status === 'REPORT_READY'"
      :title="$t(itwData.title)"
      :description-small="$t(itwData.subtitle)"
      :description="description"
      color="pink"
      @click="
        $router.push({
          name: 'collection-detail',
          params: { id: 'eggup|'+questionnaire.session },
        })
      "
    />
    <div
      v-else
      class="w-[216px] h-[216px] md:w-[248px] md:h-[248px] rounded-2xl overflow-hidden bg-red-500/[.08] flex-shrink-0
      hover:shadow-wide
      focus:outline-2
      focus:outline-red-5000"
      tabindex="0"
    >
      <div class="relative">
        <LogoInterviewRound :is-video="false" />
      </div>
      <div class="flex flex-col h-full justify-between p-4">
        <div v-if="['COMPLETED', 'PROCESSED', 'FAILED'].indexOf(questionnaire.status) > -1">
          <div
            class="w-8 h-8 rounded-full flex justify-center items-center text-xl"
            :class="{
              'bg-blue-900 text-blue-100': ['COMPLETED', 'PROCESSED'].indexOf(questionnaire.status) > -1,
              'bg-orange-500 text-orange-50': questionnaire.status === 'FAILED',
            }"
          >
            <i
              class="uil"
              :class="{
                'uil-hourglass': ['COMPLETED', 'PROCESSED'].indexOf(questionnaire.status) > -1,
                'uil-exclamation-octagon': questionnaire.status === 'FAILED',
              }"
            />
          </div>
        </div>
        <div>
          <div class="text-base sm:text-lg font-bold text-blue-900">
            {{ $t(itwData.title) }}
          </div>
          <div
            v-if="itwData.subtitle"
            class="text-blue-900 mt-5 md:mt-14"
          >
            {{ $t(itwData.subtitle) }}
          </div>
        </div>
        <Button
          v-if="itwData.has_cta"
          data-test="banner-cta"
          severity="secondary"
          icon="uil-arrow-right"
          icon-pos="right"
          class="p-0 sm:p-3 underline w-fit"
          :label="$t(itwData.has_cta)"
          @click="itwData.cta_action()"
        />
      </div>
    </div>

    <EggupModal
      v-model="showDialog"
      :mode="mode"
    />
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import EggupModal from "@/views/EggupModal.vue";
import LogoInterviewRound from "@/components/cards/LogoInterviewRound.vue";
import Button from "primevue/button";
import ForYouCard from "@/components/cards/ForYouCard.vue";
import {useQuestionnaire} from "@/composables/useQuestionnaire";

defineProps({
  description: { type: String, default: '' }
})

const mode = ref('initialize')
const showDialog = ref(false)

// Retrieve questionnaire
const { isLoadingQuestionnaire, questionnaire } = useQuestionnaire()

const itwData = computed(() => {
  if (isLoadingQuestionnaire.value) {
    return {}
  }
  return {
    'title': questionnaire.value.status === null ? 'egg_up.none_title' : (
      ['CREATED', 'STARTED'].indexOf(questionnaire.value.status) > -1 ? 'egg_up.created_title' : (
      ['COMPLETED', 'PROCESSED'].indexOf(questionnaire.value.status) > -1 ? 'egg_up.in_progress_title' : (
      questionnaire.value.status === 'FAILED' ? 'egg_up.failed_title' : (
      questionnaire.value.status === 'REPORT_READY' ? 'egg_up.done_title' : ''
    )))),
    'subtitle': ['COMPLETED', 'PROCESSED'].indexOf(questionnaire.value.status) > -1 ? 'egg_up.in_progress_subtitle' : (
      questionnaire.value.status === 'REPORT_READY' ? 'egg_up.done_subtitle' : ''
    ),
    'has_cta': questionnaire.value.status === null ? 'egg_up.none_cta' : (
      ['CREATED', 'STARTED'].indexOf(questionnaire.value.status) > -1 ? 'egg_up.created_button' : (
      questionnaire.value.status === 'FAILED' ? 'egg_up.failed_cta' : ''
    )),
    'cta_action': [null, 'CREATED', 'STARTED', 'FAILED' ].indexOf(questionnaire.value.status) > -1 ? showInterviewModal : doNothing,
  };
})
const doNothing = () => {}

const showInterviewModal = () => {
    mode.value = questionnaire.value.status === 'CREATED' ? 'continue' : 'initialize';
    showDialog.value = true;
}
</script>
