export default {
  APP_EMAIL : process.env.VUE_APP_EMAIL || "help@viblio.com",
  FRONTEND_URL : process.env.VUE_APP_FRONTEND_URL || "http://app.viblio.local" ,
  BACKEND_URL : process.env.VUE_APP_BACKEND_URL || "http://api.viblio.local" ,
  REQ_TIMEOUT: process.env.REQ_TIMEOUT || 30000,
  VUE_APP_KC_URL: process.env.VUE_APP_KC_URL || "http://localhost:8080/auth",
  VUE_APP_KC_REALM: process.env.VUE_APP_KC_REALM || "prod",
  VUE_APP_KC_CLIENT_ID: process.env.VUE_APP_KC_CLIENT_ID || "www-prod",
  VUE_APP_ADD_SSO_ATTEMPT_TO_EXTERNAL_URLS: process.env.VUE_APP_ADD_SSO_ATTEMPT_TO_EXTERNAL_URLS || 0,
  MATOMO_SITE_ID: process.env.VUE_APP_MATOMO_SITE_ID || 0,
  SENTRY_DNS: process.env.VUE_APP_SENTRY_DNS || '',
  SENTRY_ENV: process.env.VUE_APP_SENTRY_ENV || 'Local',
  SURVEY_QUIZ_IT: process.env.VUE_APP_SURVEY_QUIZ_IT || 'https://tally.so/r/w8dR0P',
  SURVEY_QUIZ_EN: process.env.VUE_APP_SURVEY_QUIZ_EN || 'https://tally.so/r/wzKL71',
  DIGITIAMO_SCRIPT: process.env.VUE_APP_DIGITIAMO_SCRIPT || '',
  BACKEND_API_KEY: process.env.VUE_APP_BACKEND_API_KEY || '',
  ENC_KEY: process.env.VUE_APP_ENC_KEY || '',
  INI_VEC: process.env.VUE_APP_INI_VEC || '',
};
