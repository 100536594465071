<template>
  <div
    class="fixed w-[300px] h-full px-4 py-6 bg-white border-r [border-right-style:solid] border-gray-200 flex-col justify-between flex z-10"
  >
    <div class="h-full flex flex-col justify-between overflow-auto">
      <div class="flex flex-col self-stretch justify-start items-start gap-8">
        <OrganizationItem v-if="isAuthenticated" />
        <PanelMenu
          v-model:expandedKeys="expandedKeys"
          :model="items"
          class="w-full flex flex-col self-stretch justify-start items-start gap-1"
          @update:expanded-keys="upExpandedKeys"
          @panel-close="panelClose"
        >
          <template #item="{ item, active, hasSubmenu }">
            <a
              :href="item.url"
              :target="item.target"
              class="no-underline"
            >
              <span
                :class="{
                  'font-bold': !item.subItem,
                  'text-gray-900 font-bold': active && (userIsAdmin() || userIsSales()),
                  'text-blue-700 font-bold': active && !(userIsAdmin() || userIsSales()),
                }"
              >
                <i
                  class="uil "
                  :class="item.icon"
                />
              </span>
              <span
                :class="{
                  'font-bold': !item.subItem,
                  'text-gray-900 font-bold': active && (userIsAdmin() || userIsSales()),
                  'text-blue-700 font-bold': active && !(userIsAdmin() || userIsSales()),
                }"
                class="text-base ml-2"
              >
                {{ $t(item.label) }}
                <span
                  v-if="item.desktopOnly"
                  class="text-gray-500 text-xs font-bold flex md:hidden"
                >
                  {{ $t('general.desktop_only') }}
                </span>
              </span>
              <span
                v-if="hasSubmenu"
                class="uil uil-angle-down text-primary ml-auto"
              />
            </a>
          </template>
        </PanelMenu>
      </div>
      <div class="flex flex-col">
        <Alert
          v-if="
            isAuthenticated &&
              userIsAnyAdmin() &&
              currentUser.organization_info.pay_in_app &&
              !currentUser.organization_info.payment_attached_date &&
              !isMobile
          "
          :title="
            $t('sidebar.missing_payment_title', { count: planData.totalDays })
          "
          :type="planData.trialWarning ? 'warning' : 'info'"
        >
          <template #content>
            <p class="text-xs">
              {{ $t('sidebar.missing_payment_text') }}
            </p>
          </template>
        </Alert>
        <CareerCoachBanner v-if="currentUser?.addon_catalogs && currentUser?.addon_catalogs.indexOf('career_coach') > -1" />
      </div>
    </div>
    <!-- FOOTER -->
    <div class="flex-col justify-start items-start gap-4 flex bg-white h-max shrink-0">
      <!-- line-divider -->
      <div class="self-stretch h-px bg-gray-200" />
      <div class="gap-2 hidden md:flex">
        <div class="text-gray-900 text-sm leading-tight">
          Powered by
        </div>
        <img
          class="h-4"
          src="../../assets/logo_viblio_transparent_short_blue.svg"
          alt="logo viblio"
        >
      </div>
      <div class="flex flex-col md:hidden gap-2">
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toSupport"
        >
          <i class="uil uil-envelope text-gray-500" />
          <div class="text-sm">
            {{ $t('support.nav_bar_text') }}
          </div>
        </div>
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toProfile"
        >
          <i class="uil uil-user text-gray-500" />
          <div class="text-sm">
            {{ $t('user.profile') }}
          </div>
        </div>
        <a
          data-test="logout"
          class="flex gap-2 cursor-pointer"
          @click="logoutAndRedirect"
        >
          <div class="text-sm">
            {{ $t('user.logout') }}
          </div>
        </a>
      </div>
    </div>
    <Dialog
      v-model:visible="showProfileDialog"
      class="w-full sm:w-[1040px] m-4"
      modal
      :draggable="false"
      :header="$t('user.profile')"
    >
      <Profile
        @closeDialog="
          () => {
            showProfileDialog = false
          }
        "
      />
    </Dialog>
    <Dialog
      v-model:visible="showSupportDialog"
      class="m-4"
      :header="$t('support.title')"
      modal
      :draggable="false"
    >
      <Support
        @closeDialog="
          () => {
            showSupportDialog = false
          }
        "
      />
    </Dialog>
  </div>
</template>
<script setup>
import { computed, defineEmits, ref } from 'vue'
import {useRouter, useRoute} from 'vue-router'
import { useWindowSize } from '@vueuse/core'
import { useUserData, useUserRoles } from '@/composables/userData'

import Dialog from 'primevue/dialog'
import PanelMenu from 'primevue/panelmenu'

import Alert from '@/components/Alert.vue'
import Profile from '@/components/Profile.vue'
import CareerCoachBanner from '@/components/sidebar/CareerCoachBanner.vue'
import OrganizationItem from '@/components/sidebar/OrganizationItem.vue'
import Support from '@/components/Support.vue'

const { isAuthenticated, logoutUser, getUser } = useUserData()
const { userOrganizationId, userIsMember, userIsManager, userIsAdmin, userIsSales, userIsAnyAdmin } = useUserRoles()

const router = useRouter()
const currentUser = getUser()
const route = useRoute()
const currentRoute = computed(() => router.currentRoute.value)
const currentOrganizationId = computed(() => {
    return ['organization-plans', 'organization-users', 'organization-groups', 'organization-import-users'].indexOf(route?.name) > -1 ? route?.params?.id : userOrganizationId()
  }
)

const isItemActive = (itemRoutes) => {
  return itemRoutes.includes(currentRoute.value?.name)
}

const isActiveMap = computed(() => {
  return {
    'groups': isItemActive(['organization-groups']),
    'plans': isItemActive(['organization-plans']),
    'users': isItemActive(['organization-users']),
  }
})

const managerManageItem =
  {
    label: 'sidebar.groups',
    key: 'groups',
    subItem: true,
    active: isActiveMap.value['groups'],
    command: () =>
      navigate({
        name: 'organization-groups',
        params: { id: currentOrganizationId.value },
      }),
  }

const manageItems = [
  {
    label: 'sidebar.plans',
    key: 'plans',
    subItem: true,
    active: isActiveMap.value['plans'],
    command: () =>
      navigate({
        name: 'organization-plans',
        params: { id: currentOrganizationId.value }
      }),
  },
  {
    label: 'sidebar.users',
    key: 'users',
    subItem: true,
    active: isActiveMap.value['users'],
    command: () =>
      navigate({
        name: 'organization-users',
        params: { id: currentOrganizationId.value },
      }),
  },
  managerManageItem
]


// const orgItem = [{
//   key: 'organizations',
//   label: 'sidebar.organizations',
//   active: isItemActive(['organizations']),
//   items: manageItems,
//   command: () =>
//     navigate({
//       name: 'organizations',
//       params: { id: userOrganizationId() },
//     }),
// }]
const oldEx =ref([])
const expandedKeys = ref({})
if (userIsAdmin() || userIsSales()) {
  expandedKeys.value = {'collections': true, 'manage': true}
  oldEx.value = expandedKeys.value
}
const upExpandedKeys = (event) => {
  let item = Object.keys(event).filter(k => Object.keys(oldEx.value).indexOf(k) === -1)
  if (item) {
    item = item[0]
  }
  if (userIsAdmin() || userIsSales()) {
    expandedKeys.value = {'collections': true, 'manage': true}
  } else {
    if (['plans', 'users', 'groups'].indexOf(item) > -1) {
      expandedKeys.value = {'manage': true}
    }
    else if (['collections_personal','collections_shared', 'collections_company'].indexOf(item) > -1) {
      expandedKeys.value = {'collections': true}
    } else {
      expandedKeys.value = {}
    }
  }
  expandedKeys.value[item] = true;
  oldEx.value = expandedKeys.value
}
const panelClose = (event) => {
  if((userIsAdmin() || userIsSales()) && event.item?.key === 'manage') {
    expandedKeys.value['manage'] = true
  }
}

const emit = defineEmits(['close', 'loadingPage'])
const { width } = useWindowSize()
const isMobile = computed(() => width.value < 600)

const navigate = (route) => {
// const navigate = (route, loading) => {
  // if(loading){
  //   emit('loadingPage')
  // }
  router.push(route)
  emit('close')
}

const items = ref([]);
items.value = [
  {
    label: 'sidebar.for_you',
    key: 'for_you',
    icon: 'uil-bookmark',
    // disabled: true,
    active: isItemActive(['ForYou']),
    command: () => {navigate({ name: 'ForYou' })}
  }, {
    label: 'sidebar.collections',
    icon: 'uil-books',
    key: 'collections',
    items: [{
      label: 'sidebar.collections_personal',
        key: 'collections_personal',
      subItem: true,
      active:
        currentRoute.value?.name === 'collections-list' &&
        currentRoute.value.params?.collectionType === 'personal',
      command: () =>
        navigate({
          name: 'collections-list',
          params: {collectionType: 'personal'}
        }),
    },
      {
        label: 'sidebar.collections_shared',
        key: 'collections_shared',
        subItem: true,
        active:
          currentRoute.value?.name === 'collections-list' &&
          currentRoute.value.params?.collectionType === 'shared_with_me',
        command: () =>
          navigate({
            name: 'collections-list',
            params: {collectionType: 'shared_with_me'}
          }),
      },
      {
        label: 'sidebar.collections_company',
        key: 'collections_company',
        subItem: true,
        active:
          currentRoute.value?.name === 'collections-list' &&
          currentRoute.value.params?.collectionType === 'company',
        command: () =>
          navigate({
            name: 'collections-list',
            params: {collectionType: 'company'}
          }),
      },
    ]
  }, {
    label: 'sidebar.explore',
    key: 'explore',
    icon: 'uil-search',
    active: isItemActive(['search', 'search-results']),
    command: () => {
      navigate({name: 'search'})
    },
  }, {
    label: 'sidebar.analysis',
    key: 'analysis',
    icon: 'uil-graph-bar',
    desktopOnly: true,
    active: isItemActive(['dashboard']),
    command: () => {
      navigate({name: 'dashboard'}, true)
    },
  }
]

if (!userIsMember()) {
  items.value.push({
    key: 'manage',
    label: (userIsAdmin() || userIsSales()) ? 'sidebar.organizations' : 'sidebar.manage',
    icon: "uil-cog",
    items: userIsManager() ? [managerManageItem] : manageItems,
    active: isItemActive(['organizations']),
    command: () => {
      if (userIsAdmin() || userIsSales()) {
        navigate({
          name: 'organizations',
          params: {id: userOrganizationId()},
        })
      }
    }
  })
}
// const itemsC = computed(() => items.value);


const planData = currentUser.getCurrentPlanTrialEndDate()

// const navigateToSettings = () => {
//   const route = userIsAdmin() ? { name: 'organizations' } : { name: 'organization', params: { id: userOrganizationId() } }
//   router.push(route)
// }

const logoutAndRedirect = () => {
  logoutUser()
}

const showProfileDialog = ref(false)
const showSupportDialog = ref(false)
const toProfile = () => {
  showProfileDialog.value = true
}
const toSupport = () => {
  showSupportDialog.value = true
}
</script>
