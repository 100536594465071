<template>
  <nav
    v-if="isAuthenticated"
    id="nav"
    class="fixed top-0 flex items-center border-b border-b-gray-200 [border-bottom-style:solid] right-0 px-6 py-3 nav bg-white z-10"
    data-test="navbar"
    :class="{
      'w-full px-4 md:px-20': currentConfig === 'minimal',
      'w-full md:w-[calc(100%-300px)]': currentConfig === 'full'
    }"
  >
    <div
      v-if="currentConfig === 'full'"
      class="flex gap-2 items-center md:hidden"
    >
      <i
        class="uil uil-bars cursor-pointer text-2xl"
        @click="$emit('open-side-bar')"
      />
      <img
        class="h-8 cursor-pointer"
        src="../../assets/logo_viblio_transparent_short_blue.svg"
        @click="$router.push({ name: 'ForYou' })"
      >
    </div>
    <img
      v-if="currentConfig === 'minimal'"
      class="h-8"
      src="../../assets/logo_viblio_transparent_short_blue.svg"
    >
    <AppBreadcrumb
      v-if="currentConfig === 'full'"
    />
    <div class="ml-auto flex items-center gap-4">
      <div :class="{'sm:block hidden': currentConfig === 'full', 'block': currentConfig === 'minimal'}">
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toSupport"
        >
          <div class="hidden md:block text-sm">
            {{ $t('support.nav_bar_text') }}
          </div>
          <i class="uil uil-envelope text-gray-500" />
        </div>
      </div>
      <i
        v-if="currentConfig === 'full'"
        class="uil uil-user cursor-pointer text-gray-500 hidden sm:block"
        data-test="user-profile"
        @click="toggleProfileMenu"
      />
      <Menu
        id="overlay_menu"
        ref="menu"
        :model="[
          {
            label: $t('user.profile'),
            command: toProfile
          },
          {
            label: $t('user.logout'),
            command: logoutAndRedirect
          }
        ]"
        :popup="true"
      />
      <a
        v-if="currentConfig === 'minimal'"
        data-test="logout"
        class="flex gap-2 cursor-pointer"
        @click="logoutAndRedirect"
      >
        <div class="hidden md:block text-sm">
          {{ $t('user.logout') }}
        </div>
        <i class="uil uil-multiply text-gray-500" />
      </a>
    </div>
    <Dialog
      v-model:visible="showProfileDialog"
      class="w-full sm:w-[1040px] m-4"
      modal
      :draggable="false"
      :header="$t('user.profile')"
    >
      <Profile @closeDialog="() => { showProfileDialog = false }" />
    </Dialog>
    <Dialog
      v-model:visible="showSupportDialog"
      class="w-full sm:w-[600px] m-4"
      modal
      :draggable="false"
      :header="$t('support.title')"
    >
      <Support @closeDialog="() => { showSupportDialog = false }" />
    </Dialog>
  </nav>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import Menu from "primevue/menu";

import AppBreadcrumb from "@/components/AppBreadcrumb.vue";
import Profile from "@/components/Profile.vue";
import { useUserData } from '@/composables/userData'
import Support from "@/components/Support.vue";
import Dialog from "primevue/dialog";

const { isAuthenticated, logoutUser } = useUserData();
const route = useRoute()
const currentRouteName = computed(() => route.name)

const config = {
  'welcome': 'minimal',
  'subscription': 'minimal'
}
const currentConfig = computed(() => config[currentRouteName.value] || 'full')

const showSupportDialog = ref(false)
const showProfileDialog = ref(false)

const logoutAndRedirect = () => {
  logoutUser();
}
const toProfile = () => {
  showProfileDialog.value = true;
}

const menu = ref();

const toggleProfileMenu = () => {
    menu.value.toggle(event);
}

const toSupport = () => {
  showSupportDialog.value = true;
}

</script>
