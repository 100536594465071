import TrackedModel from "@/resources/TrackedModel";
import Plan from "@/resources/Plan";
import {useUserRoles} from "@/composables/userData";

export default class Organization extends TrackedModel {

  constructor(data) {
    super(data);
    const { userIsAdmin, userIsOnlySales, userIsSalesManager, userId } = useUserRoles();
    this.name = data.name || '';
    this.address = data.address || null;
    this.created_by = data.created_by || '';
    this.referent = data.referent || '';
    this.referent_fullname = data.referent_fullname || '';
    this.referent_email = data.referent_email || '';
    this.valid_users_counter = data.valid_users_counter || 0;
    this.total_plans = data.total_plans || 0;
    this.total_addons = data.total_addons || 0;
    this.address_detail = data.address_detail || null;
    this.province = data.province || null;
    this.legal_address = data.legal_address || null;
    this.zip_code = data.zip_code || null;
    this.city = data.city || null;
    this.country = data.country || null;
    this.cf_code = data.cf_code || null;
    this.vat_code = data.vat_code || null;
    this.phone = data.phone || null;
    this.email = data.email || '';
    this.description = data.description || '';
    this.status = data.status || '';
    this.url = data.url || '';
    this.domain = data.domain || '';

    this.billing_name = data.billing_name || '';
    this.sdi_code = data.sdi_code || '';
    this.pec = data.pec || '';
    this.iban = data.iban || '';
    this.riba = data.riba || '';

    this.personal = data.personal || false;
    this.source = data.source || '';
    this.stripe_customer_id = data.stripe_customer_id || '';
    this.pay_in_app = data.pay_in_app || false;
    this.trial = data.trial || true;
    this.trial_days = data.trial_days || 14;
    this.payment_attached_date = data.payment_attached_date || null;
    // this.users_counter = data.users_counter || 0;
    this.active_users_counter = data.active_users_counter || 0;
    this.groups_counter = data.groups_counter || 0;
    this.plan_status = data.plan_status || null;
    this.plans = data.plans || [];
    this.addons = data.addons || [];
    this.current_plan = null;
    this.current_available_licences = null;
    if (this.pay_in_app) {
      this.current_available_licences = this.active_users_counter < 250;
      this.current_plan = this.plans.length === 1 ? new Plan(this.plans[0]) : null;
    }
    else if (this.plans.length > 0) {
      this.current_plan = this.plans.length === 1 ? new Plan(this.plans[0]) : null;
      this.current_available_licences = this.plans.reduce((partialSum, p) => partialSum + (p.total_license - p.used_license), 0) > 0;
    }
    this.sales = data.sales || ''
    this.disabled = this.status === "DELETED";
    this.permissions.delete = false;
    this.permissions.select = false;
    this.permissions.open = userIsAdmin() || userIsSalesManager() || (userIsOnlySales() && this.sales === userId());
    this.permissions.edit = userIsAdmin() || userIsSalesManager() || (userIsOnlySales() && this.sales === userId());
    this.permissions.convert = userIsAdmin() && !this.pay_in_app && this.status === 'ACTIVE' && this.plans.length === 1;

    // only for creation
    this.referent_first_name = data.referent_first_name || '';
    this.referent_last_name = data.referent_last_name || '';
    this.referent_is_active = data.referent_is_active || true;
    this.referent_lang = data.referent_lang || '';
    this.plan_start_date = data.plan_start_date || null;
    this.plan_expiration_date = data.plan_expiration_date || null;
    this.plan_total_license = data.plan_total_license || 0;
    this.plan_billing_frequency = data.plan_billing_frequency || '';
    this.plan_price = data.plan_price || 0;
    this.addon_catalog_obj = data.addon_catalog_obj || null;
    this.addon_catalog = data.addon_catalog || '';
    this.addon_total_license = data.addon_total_license || 0;
    this.addon_price = data.addon_price || 0;
    this.addon_start_date = data.addon_start_date || null;

    return this;
  }
  convertForListBoxOption() {
    return {
      key: this.id || 'null',
      label: this.name || 'N/D',
      personal: this.personal || false,
      pay_in_app: this.pay_in_app || false,
      referent_fullname: this.referent_fullname || '',
      // users_counter: this.users_counter || '0',
      active_users_counter: this.active_users_counter || '0',
      groups_counter: this.groups_counter || '0',
      object: this,
    };
  }

  static fromApiResult(recordData) {
    const recordObj = new Organization(recordData);
    return recordObj;
  }
  thisForApi() {
    return Organization.forApi(this);
  }

  static forApi(recordObj) {
    let recordData = {};
    const dateFields = ['plan_start_date', 'plan_expiration_date', 'addon_start_date'];
    Object.getOwnPropertyNames(recordObj).forEach(key => {
      if (dateFields.indexOf(key) > -1 && recordObj[key]) {
        recordData[key] = TrackedModel.asInputDate(recordObj[key]);
      } else {
        recordData[key] = recordObj[key];
      }
    });
    return recordData;
  }

}
