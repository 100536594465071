import {create, patch, addResource, removeResource, deleteColl, getCollections} from "@/api/collections.js";
import { ref } from "vue";
import Collection from "@/resources/Collection";
import { ErrorBus, ErrorCommands } from "@/eventBus";
import {useUserRoles} from "@/composables/userData";
import { pushToDataLayer } from "./useGoogleTagManager";

// import { useUserData } from "@/composables/userData";

const userCollections = ref([])
const loadingCollections = ref(false)

function useUserCollections() {

    const { userId } = useUserRoles();
    const currentUserId = userId();

    const deleteCollection = async id => {
      const result = await deleteColl(id);
      if(result && result.error){
        ErrorBus.emit(ErrorCommands.ERROR, { message: result?.error });
        return { error: result?.error || 'errors.general'}
      }
      return result;
    };

    // const getCollectionById = async id => {
    //     const results = await getById(id);
    //     if (results && !results.error) {
    //         return  Collection.fromApiResult(results);
    //     }
    //     ErrorBus.emit(ErrorCommands.ERROR, { message: results?.error});
    //
    //     // not found
    // };

    // TODO: Add updateUserCollections(result) for getCollections (for sharedcollections maybe)
    // const getCollections = async() => {
    //     loadingCollections.value = true;
    //     const results = await playlists();
    //     loadingCollections.value = false;
    //     if (results && results.length) {
    //         return results;
    //     }
    //     ErrorBus.emit(ErrorCommands.ERROR, { message: results?.error });
    //     return { error: results?.error || 'errors.general' }
    // };


    const getUserCollections = async(forceRefetch = false) => {
        if(forceRefetch) {
          // const results = await list(, collectionCreator.user);
          loadingCollections.value = true;

          let userCollectionsFilters = [{
            field: 'creator',
            value: 'USER'
          }, {
            field: 'user',
            value: currentUserId
          }];
          const results = await getCollections(-1, 0, userCollectionsFilters);
          if(results && !results.error){
            userCollections.value = results.results;
            loadingCollections.value = false;
          } else {
            ErrorBus.emit(ErrorCommands.ERROR, { message: results?.error || 'errors.general' });
            // updateUserCollections([]);
            loadingCollections.value = false;
            return { error: results?.error || 'errors.general'};
          }
        }
        return userCollections || [];

    };

    const createCollection = async(title, description, resources = []) => {
        const result = await create({ title, description, resources });
        if(result.error){
          return { error: result?.error || 'errors.general'}
        }
        await getUserCollections(true);
        pushToDataLayer({
          event:'add_collection',
          title:title,
          collection_type: 'Personal'
        })
        return result;
    };

    const updateCollection = async data => {
      const result = await patch(Collection.getDataForPatchOperation(data));

      if(!result || result.error){
        ErrorBus.emit(ErrorCommands.ERROR, { message: result?.error });
        return { error: result?.error || 'errors.general'}
      }
      await getUserCollections(true);
      return result;
    };
    const addResourceToCollection = async data => {
      const result = await addResource(data);

      if(!result || result.error){
        return { error: result?.error || 'errors.general'}
      }
      // await getUserCollections(true);
      // TODO result is the updated collection, i can skip `getUserCollections` if i can update the memory.
      return result;
    };
    const removeResourceFromCollection = async data => {
      const result = await removeResource(data);
      if(!result || result.error){
        ErrorBus.emit(ErrorCommands.ERROR, { message: result?.error });
        return { error: result?.error || 'errors.general'}
      }
      return result;
    };

    return {
        createCollection,
        getUserCollections,
        addResourceToCollection,
        removeResourceFromCollection,
        loadingCollections,
        updateCollection,
        deleteCollection,
        userCollections,
    };
}

export { useUserCollections };
