
const courseProviders = {
  coursera: {
    label: "Coursera",
    website: "https://www.coursera.org/",
    logo: "coursera.png",
    slug: "coursera"
  },
  edx: {
    label: "EDX",
    website: "https://www.edx.org/",
    logo: "edx.png",
    slug: "edx"
  },
  future_learn: {
    label: "Future Learn",
    website: "https://www.futurelearn.com/",
    logo: "future_learn.png",
    slug: "future_learn"
  },
  harvard_business_school: {
    label: "Harvard Business School",
    website: "https://online.hbs.edu/",
    logo: "harvard_business_school.png",
    slug: "harvard_business_school"
  },
  mxe: {
    label: "mixergy",
    website: "https://mixergy.com/",
    logo: "mixergy.png",
    slug: "mxe"
  },
  ted: {
    label: "TEDx",
    website: "https://www.ted.com/podcasts",
    logo: "ted.png",
    slug: "ted"
  },
  uda: {
    label: "Udacity",
    website: "https://www.udacity.com/",
    logo: "udacity.png",
    slug: "uda"
  },
  udemy: {
    label: "Udemy",
    website: "https://www.udemy.com",
    logo: "udemy.png",
    slug: "udemy"
  },
  youtube: {
    label: "Youtube",
    website: "https://www.youtube.com",
    logo: "youtube.png",
    slug:"youtube"
  },
  polimi: {
    label: "Polimi",
    website: "https://www.polimi.it",
    logo: "polimi.png",
    slug:"polimi"
  },
  aws: {
    label: "AWS",
    website: "https://explore.skillbuilder.aws",
    logo: "aws.png",
    slug:"aws"
  },
  codecademy: {
    label: "Codecademy",
    website: "https://www.codecademy.com/",
    logo: "codecademy.png",
    slug:"codecademy"
  },
  spreaker: {
    label: "Spreaker",
    website: "https://www.spreaker.com/",
    logo: "spreaker.png",
    slug:"spreaker"
  },
  spotify: {
    label: "Spotify",
    website: "https://open.spotify.com/",
    logo: "spotify.png",
    slug:"spotify"
  },
  steel_university: {
    label: "Steel University",
    website: "https://steeluniversity.org/",
    logo: "steel_university.png",
    slug:"steel_university"
  },
  learning_365: {
    label: "Learning 365",
    website: "https://learning365.it/",
    logo: "learning365.png",
    slug:"learning_365"
  },
  infinance: {
    label: "inFinance",
    website: "https://www.infinance.it/",
    logo: "infinance.png",
    slug:"infinance"
  },
  cnn: {
    label: "CNN",
    website: "https://edition.cnn.com/",
    logo: "cnn.png",
    slug:"cnn"
  },
  google_drive: {
    label: "Google Drive",
    website: "https://www.google.com/",
    logo: "google_drive.png",
    slug:"google_drive"
  },
  p_learning: {
    label: "P-Learning",
    website: "https://p-learning.com/",
    logo: "p_learning.png",
    slug:"p_learning"
  },
  p_copernico: {
    label: "Piazza Copernico",
    website: "https://www.piazzacopernico.it/",
    logo: "p_copernico.png",
    slug:"p_copernico"
  },
  i_editore: {
    label: "Interattiva Editore",
    website: "https://www.interattivaeditore.com/",
    logo: "i_editore.png",
    slug:"i_editore"
  }
};

function getProvider(id) {
  return courseProviders[id];
}

export default function useCourseProviders() {
  return { courseProviders, getProvider };
}

// export const providersForProfile = (profile) => {
//   // console.log({ profile})
//   let ret = [];
//   if(profile == BUSINESS_PROFILES.fullon_business_user) {
//     ret = [
//       courseProviders.coursera,
//       courseProviders.ted,
//       courseProviders.harvard_business_school,
//       courseProviders.mxe
//     ];
//   }
//   if(profile == BUSINESS_PROFILES.business_user) {
//     ret = [
//       courseProviders.ted,
//       courseProviders.harvard_business_school,
//       courseProviders.mxe,
//       courseProviders.udemy,
//       courseProviders.uda,
//       courseProviders.future_learn,
//       courseProviders.edx
//     ];
//   }
//   return ret;
// }
